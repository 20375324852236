import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import {
  IoTimer,
  IoSync,
  IoWarning,
  IoPhonePortrait,
  IoCalendar,
  IoPeople,
  IoCloudDownload,
  IoLocation,
  IoCheckmarkCircle,
  IoShieldCheckmark,
} from "react-icons/io5";
import { IoMdOpen } from "react-icons/io";

import { useModal } from "../components/useModal";

import {
  ImageGraphic,
  Breadcrumb,
  CustomerQuote,
  CustomerQuoteLarge,
  CallToAction,
} from "../components/site";
import {
  Container,
  Wrapper,
  Row,
  Box,
  Button,
  FlexButtons,
} from "../components/util";
import Image from "../components/image";
import Layout from "../components/layout";
import Seo from "../components/seo";

import DroppahPayHeroInt from "../images/logos/PayHero/DroppahPlusPayHero.svg";
import PayHeroPlusDroppah from "../images/logos/PayHero/DroppahPlusPayHero.svg";

const NumberBadge = styled.div`
  background-color: ${(props) => props.theme.colours.white};
  color: ${(props) => props.theme.colours.black};
  border-radius: 35px;
  width: 100%;
  height: 100%;
  max-width: 35px;
  max-height: 35px;
  line-height: 35px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  text-align: center;
  user-select: none;
  margin-top: -7px;
`;

const PayHero = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  const AdditionalFeatures = [
    {
      icon: <IoWarning />,
      title: "Monitor employee overtime",
      description:
        "Stay on top of your labour costs and monitor employee burnout by easily seeing who's working longer than they should be.",
    },
    {
      icon: <IoPhonePortrait />,
      title: "Manage rosters on the go",
      description:
        "Droppah has been built to work on any device. It’s an easy to use roster app, whether you're at the office, sitting at the bar or on the bus.",
    },
    {
      icon: <IoCalendar />,
      title: "Staff availability",
      description: [
        <span key={0}>Set available days & times and sync leave from </span>,
        <Link key={1} to="/payroll">
          payroll
        </Link>,
        <span key={2}>
          {" "}
          to ensure employees are only rostered when they're available.
        </span>,
      ],
    },
    {
      icon: <IoPeople />,
      title: "Delegate roster management",
      description:
        "Organise your business into groups of employees and assign managers to oversee rosters, timesheets and the photo time clock.",
    },
    {
      icon: <IoCloudDownload />,
      title: "Download rosters",
      description:
        "As well as emailing individual shifts to everyone when you publish your roster, we'll also provide you with a PDF to download & print out.",
    },
    {
      icon: <IoLocation />,
      title: "NZ rostering software",
      description:
        "Built by Kiwis for NZ businesses. When you need support you’ll be talking to our friendly Wellington-based team.",
    },
  ];

  return (
    <Layout>
      <Seo
        title="Droppah + PayHero Integration | Rosters & Payroll"
        description="Discover Droppah, staff rostering, time tracking & attendance software for your business. Simplify rostering & pay your staff right."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={30} centerOnMobile>
              <img
                src={PayHeroPlusDroppah}
                alt="PayHero + Droppah Integration"
                height="40"
              />
              <Box stackGap={10}>
                <h1>
                  Complete your stellar service with integrated NZ payroll
                </h1>
                <h4>
                  Connect to PayHero and pay your team right with award-winning,
                  compliant Kiwi payroll.
                </h4>
              </Box>
              <Box css={{ display: "table" }} stackGap={15}>
                <FlexButtons>
                  <Button to="/signup" className="primary -lg gtm-cta">
                    Kick things off with Droppah
                  </Button>
                  <Button
                    to="https://www.payhero.co.nz/signup"
                    className="white -lg"
                    atag
                    externalLink
                  >
                    Discover PayHero{" "}
                    <IoMdOpen css={{ top: "2px", marginLeft: "5px" }} />
                  </Button>
                </FlexButtons>
                <p
                  css={{ fontSize: "1rem", opacity: "0.5" }}
                  className="-textCenter"
                >
                  Droppah is free for businesses with up to 10 employees
                </p>
              </Box>
            </Box>
            <Box size={50}>
              <Image
                filename="Droppah_ClockAndLive_PayHero.png"
                alt="Droppah + PayHero | Rostering & time clock software"
                addShadow
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="deeppurple" className="-whiteOut">
        <Wrapper>
          <Box stackGap={40}>
            <h2 className="-textCenter -center" css={{ maxWidth: "1000px" }}>
              PayHero saves payday with headache-free online payroll software.
            </h2>
          </Box>
          <Row stackGap={80} className="-textCenter">
            <Box size={33}>
              <h3>
                <IoShieldCheckmark />
                <br />
                Pay your employees right
              </h3>
              <p>
                Track shifts with Droppah for spot-on timesheets that flow
                straight through to your{" "}
                <a href="https://www.payhero.co.nz">payroll system</a>.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoShieldCheckmark />
                <br />
                Fast rosters and real-time attendance
              </h3>
              <p>
                Build a budget-friendly roster with the right coverage, then use
                the live view to see where everyone’s at.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoShieldCheckmark />
                <br />
                No need for manual intervention
              </h3>
              <p>
                Enjoy end-to-end management of employee availability, attendance
                & pay, with no paperwork or data entry.
              </p>
            </Box>
          </Row>
          <CustomerQuote
            quote="My payroll takes me 10-15 minutes a week, and I’m able to answer all the questions from my team about their entitlements."
            name="Chris Kinnell"
            company="The Butcher’s Son"
            pic="TheButchersSon_Circle.png"
            center
          />
        </Wrapper>
      </Container>
      <Container className="-whiteOut">
        <Wrapper>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={7}>
              <h3>Spend less time on payroll (hooray!)</h3>
              <p>
                Paying your team shouldn’t take ages, so PayHero makes it easy.
                Send approved timesheets from Droppah and pay your wages in just
                a few clicks. You can even put your payroll on Autopilot.
              </p>
            </Box>
            <Box size={50}>
              <Image
                filename="PayHero_Dashboard_Power.png"
                alt="Roster App | Droppah"
                maxWidth={530}
                centerImage
                addShadow
                rounded
              />
            </Box>
          </Row>
          <Row stackGap={60} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                filename="PayHero_LeaveDetail.png"
                alt="Roster App | Droppah"
                maxWidth={530}
                centerImage
                addShadow
                rounded
              />
            </Box>
            <Box size={50} stackGap={7}>
              <h3>Make leave management easy breezy</h3>
              <p>
                PayHero has all the tools you and your employees need to request
                and approve leave. And once leave has been approved, those
                employees can’t be rostered in Droppah.
              </p>
            </Box>
          </Row>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={7}>
              <h3>Take a break from employee admin</h3>
              <p>
                Give your employees controlled access to view payslips and leave
                balances; and keep a secure record of breaks and shifts.
              </p>
            </Box>
            <Box size={50}>
              <Image
                filename="PayHero_EmpMobile.png"
                alt="Photo Time Clock | Droppah"
                maxWidth={500}
                addShadow
                centerImage
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="midnight" className="-textCenter">
        <Wrapper noPaddingBottom>
          <h2>How to get your integration sussed & sorted</h2>
          <Row stackGap={60}>
            <Box size={33} stackGap={25}>
              <NumberBadge>1</NumberBadge>
              <div>
                <h4>
                  <a
                    href="https://support.droppah.com/hc/en-us/articles/360002652216-Getting-Started-with-Droppah-from-PayHero"
                    target="_blank"
                    css={{ color: "#fff" }}
                  >
                    Sync up
                  </a>{" "}
                  your people, teams and skills with PayHero
                </h4>
              </div>
            </Box>
            <Box size={33} stackGap={25}>
              <NumberBadge>2</NumberBadge>
              <div>
                <h4>Do your rostering thang with Droppah</h4>
              </div>
            </Box>
            <Box size={33} stackGap={25}>
              <NumberBadge>3</NumberBadge>
              <div>
                <h4>
                  Send all that time to payroll so everyone is paid correctly
                </h4>
              </div>
            </Box>
          </Row>
          <CustomerQuote
            quote="The PayHero and Droppah integration is a crucial link. The accounts team used to chase everyone up to confirm timesheets, but now the process is simple for everyone"
            name="Kieran Wallace"
            company="1154 and Scopa"
            pic="Kieran_Circle.png"
            center
          />
          <Image
            filename="PayHeroHero_Cropped.png"
            alt="Roster App | Droppah"
            maxWidth={1000}
            centerImage
            addShadow
          />
        </Wrapper>
      </Container>
      <CallToAction
        title="Connect PayHero & Droppah to get your mojo back"
        description="Create next-level rosters and pay your staff right."
        buttonText="Kick things off with Droppah"
        hideDemoButton
        showPayHeroButton
      />
    </Layout>
  );
};

export default PayHero;
